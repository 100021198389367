<mat-progress-bar *ngIf="league == null" color="accent" mode="query">
</mat-progress-bar>
<ng-container *ngIf="league != null && league.isErrored && league.leagueGuid == currentNflGuid">
  <!-- TODO: this is stupid -->
  <div class="containerX">
  <h2>2024 - 2025 NFL SEASON</h2>
  <p>This schedule has not been loaded yet, but will be loaded shortly after the NFL releases it.
  </p>
  <p>
    Follow <a href="https://x.com/RayLehnhoff">@RayLehnhoff</a> on X or <a href="https://discord.gg/uFr4xt768Y"><img alt="discord" title="discord" src="https://static.playoffpredictors.com/site/discord.svg" width="32" height="32"/> hang out with us on Discord</a> for updates.
  </p>
</div>
</ng-container>

<ng-container *ngIf="league != null && league.isErrored && league.leagueGuid != currentNflGuid">
  <p>This schedule has not been loaded yet or there was a problem retrieving it. If you're here around the schedule release, try again later. If you didn't expect to see this error, reach out to <a href="https://twitter.com/RayLehnhoff">@RayLehnhoff</a></p>
</ng-container>
<ng-container *ngIf="league != null && !league.isErrored">
  <app-league-styles></app-league-styles>
  <div class="containerX">
    <!---->
    <div class="box" fxlayout="row" style="flex-direction: row;box-sizing:border-box; display: flex;">
      <div fxFlex="100%" style="flex: 1 1 100%;box-sizing:border-box; flex-direction: row; max-width: 100%; display: flex;">
        <app-control-bar [leagueGuid]="league.leagueGuid"></app-control-bar>
      </div>
    </div>
    <div class="box" fxlayout="row" style="flex-direction: row;box-sizing:border-box; display: flex;">
      <div color="primary" style="flex: 1 1 100%;text-align:center;text-transform:uppercase">
        <h2>{{ league?.leagueName }}</h2>
      </div>
    </div>

    <div class="box" fxlayout="row" style="box-sizing:border-box; display: flex;flex:1 1 100%">
      <div class="conferenceContainer cc-start" *ngIf="!bottomStandingsVisible">
        <div>
          <mat-chip-list class="div-conf-chip-wrapper">
            <mat-chip [selected]="!userConfig.nfl.divToggle" (click)="userConfig.nfl.divToggle = false" selectable="true" color="primary">Conference</mat-chip>
            <mat-chip [selected]="userConfig.nfl.divToggle" (click)="userConfig.nfl.divToggle = true" selectable="true" color="primary">Division</mat-chip>
          </mat-chip-list>
          <app-conference-standings [conference]="league.conf1Name" *ngIf="!userConfig.nfl.divToggle" style="justify-content:flex-start;"></app-conference-standings>
          <app-division-standings [conference]="league.conf1Name" *ngIf="userConfig.nfl.divToggle" style="justify-content:flex-start;"></app-division-standings>
          <app-freestar [adName]="'playoffpredictors_rail_left'" [adDimensions]="'__160x600 __0x0'"></app-freestar>
        </div>
      </div>
      <mat-tab-group mat-stretch-tabs #tabGroup class="conference-tab-group">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon title="schedule" aria-hidden="false" aria-label="schedule">calendar_today</mat-icon>
          </ng-template>
          <app-schedule-control-bar></app-schedule-control-bar>
          <h3 class="text-center">{{ weekService?.weekServiceName }}</h3>
          <p class="text-center" *ngIf="league.playoffTeams.length > 0">
            Want to predict <strong style="color:red">just</strong> the Playoffs? Head over to the <a [routerLink]="['/football/nfl/playoffpicture/'+ league.leagueGuid]">Playoff Bracket</a>
          </p>
          <ng-container *ngIf="hasPools">
            <app-pool [tabSwapper]="tabSwapper"></app-pool>
          </ng-container>
          <div class="gameChannelContainer">
            <div class="gameChannel">
              <ng-container *ngIf="activeGames[0].length">
                <app-picker-channel [channelName]="'AFC'" [games]="activeGames[0]"></app-picker-channel>
              </ng-container>
            </div>
            <div class="gameChannel">
              <ng-container *ngIf="activeGames[1].length">
                <app-picker-channel [channelName]="'AFC vs NFC'" [games]="activeGames[1]"></app-picker-channel>
              </ng-container>
            </div>
            <div class="gameChannel">
              <ng-container *ngIf="activeGames[2].length">
                <app-picker-channel [channelName]="'NFC'" [games]="activeGames[2]"></app-picker-channel>
              </ng-container>
            </div>
          </div>
          <div class="gameChannel" style="flex-direction: row;">
            <app-freestar [adName]="'playoffpredictors_incontent_1'" [adDimensions]="'__970x250 __336x280'"></app-freestar>
          </div>
          <div style="flex: 1 1 50%; width:100%;" *ngIf="bottomStandingsVisible">
            <mat-chip-list class="div-conf-chip-wrapper" style="margin:20px;">
              <mat-chip [selected]="!userConfig.nfl.divToggle" (click)="userConfig.nfl.divToggle = false" selectable="true" color="primary">Conference</mat-chip>
              <mat-chip [selected]="userConfig.nfl.divToggle" (click)="userConfig.nfl.divToggle = true" selectable="true" color="primary">Division</mat-chip>
            </mat-chip-list>


            <div class="box" fxlayout="row" style="box-sizing:border-box; display: flex;">
              <div style="flex: 1 1 50%;box-sizing:border-box;margin-right:2px">
                <app-conference-standings [conference]="league.conf1Name" *ngIf="!userConfig.nfl.divToggle" style="justify-content:flex-start;"></app-conference-standings>
                <app-division-standings [conference]="league.conf1Name" *ngIf="userConfig.nfl.divToggle" style="justify-content:flex-start;"></app-division-standings>
              </div>

              <div style="flex: 1 1 50%;box-sizing:border-box;">
                <app-conference-standings [conference]="league.conf2Name" *ngIf="!userConfig.nfl.divToggle"></app-conference-standings>
                <app-division-standings [conference]="league.conf2Name" *ngIf="userConfig.nfl.divToggle"></app-division-standings>
              </div>
            </div>
          </div>

          <app-byes></app-byes>

          <mat-accordion>
<mat-expansion-panel>
<mat-expansion-panel-header>Legend</mat-expansion-panel-header>
  <app-legend></app-legend>
</mat-expansion-panel>

            <mat-expansion-panel expanded>
              <mat-expansion-panel-header> Draft Order </mat-expansion-panel-header>
              <mat-panel-description> NFL First Round Draft Order </mat-panel-description>
              <ng-template matExpansionPanelContent>
                  <app-draft-order></app-draft-order>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>Latest from the PlaySheet</mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <app-playsheet-posts></app-playsheet-posts>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
          <app-bracket></app-bracket>


        </mat-tab>
        <mat-tab *ngIf="!!selectedTeam" [disabled]="!selectedTeam">
          <ng-template mat-tab-label>
              <img src="https://static.playoffpredictors.com/nfl/{{selectedTeam.teamLogo || selectedTeam.teamAbbreviation}}.svg" title="{{selectedTeam.teamName}}" alt="{{selectedTeam.teamName}}" width="24px" />
          </ng-template>
          <app-selected-team [team]="selectedTeam" *ngIf="!!selectedTeam" [tabGroup]="matTabGroup"></app-selected-team>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon aria-hidden="false" aria-label="stats" title="stats">stacked_bar_chart</mat-icon>
          </ng-template>
          <ng-template matTabContent>
          <app-freestar [adName]="'playoffpredictors_incontent_nfl_bracket'" [adDimensions]="'__970x250 __336x280'"></app-freestar>
          <app-scores></app-scores>
        </ng-template>
        </mat-tab>

        <mat-tab label="leaderboard" *ngIf="hasPools">
          <ng-template mat-tab-label>
            <mat-icon aria-hidden="false" aria-label="leaderboard" title="leaderboard">leaderboard</mat-icon>
          </ng-template>
          <ng-template matTabContent>
          <app-freestar [adName]="'playoffpredictors_incontent_nfl_bracket'" [adDimensions]="'__970x250 __336x280'"></app-freestar>
          <app-resulting></app-resulting>
        </ng-template>
        </mat-tab>
        <mat-tab label="Saved Scenarios">
          <ng-template mat-tab-label>
            <mat-icon aria-hidden="false" aria-label="saved scenarios" title="saved scenarios">source</mat-icon>
          </ng-template>
          <ng-template matTabContent>
            <app-freestar [adName]="'playoffpredictors_incontent_nfl_bracket'" [adDimensions]="'__970x250 __336x280'"></app-freestar>
            <app-watchlist sportId="1"></app-watchlist>
          </ng-template>
        </mat-tab>
        <mat-tab label="Settings">
            <ng-template mat-tab-label>
              <mat-icon aria-hidden="false" aria-label="league settings" title="settings">settings</mat-icon>
          </ng-template>
          <app-settings (settingsChanged)="settingsChanged()"></app-settings>
        </mat-tab>
      </mat-tab-group>

      <div class="conferenceContainer cc-end" *ngIf="!bottomStandingsVisible">
        <div>
          <mat-chip-list class="div-conf-chip-wrapper">
            <mat-chip [selected]="!userConfig.nfl.divToggle" (click)="userConfig.nfl.divToggle = false" selectable="true" color="primary">Conference</mat-chip>
            <mat-chip [selected]="userConfig.nfl.divToggle" (click)="userConfig.nfl.divToggle = true" selectable="true" color="primary">Division</mat-chip>
          </mat-chip-list>

          <app-conference-standings [conference]="league.conf2Name" *ngIf="!userConfig.nfl.divToggle"></app-conference-standings>
          <app-division-standings [conference]="league.conf2Name" *ngIf="userConfig.nfl.divToggle"></app-division-standings>
          <app-freestar [adName]="'playoffpredictors_rail_right'" [adDimensions]="'__160x600 __0x0'"></app-freestar>

        </div>
      </div>
    </div>
  </div>
</ng-container>
